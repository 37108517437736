import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout/index.js";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Wide from "../components-styled/Wide";
import PictureSet from "../components/PictureSet";
import imgSet09 from "../imgsets/imgset09";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Über uns" keywords={[]} mdxType="SEO" />
    <Wide mdxType="Wide">
      <PictureSet imgSet={imgSet09} mdxType="PictureSet" />
    </Wide>
    <h1>{`Ziel unseres Vereins`}</h1>
    <p>{`Das hochschulgründernetz cologne (hgnc) e.V. ist ein gemeinnütziger Verein mit nur einem Ziel: `}<strong parentName="p">{`Das hgnc möchte Gründerinnen und Gründer, wenn sie Bedarf haben, bei ihren Gründungsaktivitäten unterstützen.`}</strong>{` Alle Angebote sind dabei für Studenten und Hochschulabsolventen kostenfrei. Wir finanzieren uns durch die Mitgliedsbeiträge unserer Mitglieder. Darüber hinaus wird unsere Arbeit durch ehrenamtliches Engagement ermöglicht.`}</p>
    <p>{`Wir sind überzeugt: neue Unternehmen sind gut für die Zukunft, den Standort und die Region in und um Köln, Arbeitsplätze und die Gesellschaft. Außerdem wirken sich neue Unternehmen mit ihrer Innovationskraft und mit modernen agilen Arbeitsformen auch positiv auf bereits existierende, herkömmliche oder traditionelle Unternehmen aus.`}</p>
    <p>{`Die Vereinsmitglieder sind die Kölner Hochschulen und eine Reihe weiterer wichtiger Institutionen in und um Köln, die sich alle für die Förderung von Gründerinnen und Gründern engagieren und einsetzen. So ein Verein ist relativ einmalig in den Städten Deutschlands. Wir glauben, Stärke kommt durch Zusammenarbeit und die Bündelung von Kräften.`}</p>
    <p>{`Unsere Unterstützung geschieht in Form von Veranstaltungen und Beratung. Darüber hinaus vermitteln wir Coaches und ermöglichen, Netzwerke zu knüpfen. Außerdem helfen wir dabei, Fördermittel zu bekommen.`}</p>
    <p>{`Weiterhin haben wir eine Reihe von Konzepten und Planungstools erstellt, die die Ideenfindung, die Formulierung von Geschäftsmodellen und die Aufstellung von Geschäftsplänen erleichtern können.`}</p>
    <p>{`Guckt euch gerne um…`}</p>
    <h2>{`hgnc · hochschulgründernetz cologne e.V.`}</h2>
    <p>{`Unternehmerisches Denken und Handeln sind heutzutage unverzichtbare Qualifikationen für den eigenen beruflichen Werdegang. Sei es bei der Gründung eines eigenen Unternehmens oder dem Start in die freiberufliche Tätigkeit. Aber auch als Angestellte/r im Unternehmen sollten HochschulabsolventInnen heutzutage unternehmerisch handeln können, so zusagen als „UnternehmerIn im Unternehmen“.`}</p>
    <p>{`Der Aufbau eines eigenen Unternehmens basiert auf einer guten Geschäftsidee – dies kann beispielsweise eine technologiebasierte Idee sein, die auf Forschungs- und Entwicklungsarbeiten an der Hochschule basiert. Dies kann aber auch eine Dienstleistung bzw. eine gänzlich „untechnische“ Geschäftsidee sein – zentral ist die Frage, welche Problemlösung bzw. welchen Nutzen die Idee stiftet und wer dafür bereit ist, Geld zu zahlen. Neben einer tragfähigen Idee mit einem nachhaltigen Nutzen ist die persönliche Qualifikation der/des Gründers/in entscheidend. Dabei geht es sowohl um die fachliche Spezialisierung, als auch um persönliche Kompetenzen. Beiden Aspekten trägt das hochschulgründernetz cologne mit seinem Veranstaltungsprogramm sowie der Vorlesung „Grundlagen der Existenzgründung“ und dem Praxisseminar „Businessplan“, die im Rahmen des Studium Integrale vom Professional Center der Universität zu Köln angeboten werden, Rechnung.`}</p>
    <p>{`Neben der Qualifizierung durch gründungsspezifische Seminare und Workshops stehen wir Gründungsinteressierten für alle Fragen rund um das Thema Selbstständigkeit zur Verfügung. In Einzelberatungen entwickeln wir mit ihnen die Geschäftsidee, coachen sie bei der Erstellung des Businessplans, erörtern mögliche Finanzierungsoptionen für die Umsetzung des Geschäftsmodells. Dabei können wir auch auf ein fundiertes Netzwerk außerhalb des hgnc e.V. zurückgreifen. Nahezu alle wichtigen Partner der Wirtschaft, die einem/-r jungen ExistenzgründerIn den Start erleichtern können, kooperieren mit unserem Netzwerk, stehen mit Rat und Tat zur Seite und opfern oft auch viel Zeit und Geduld, um das Thema Existenzgründung weiter voranzutreiben.`}</p>
    <p>{`Sämtliche Leistungen des hgnc sind für Studierende, MitarbeiterInnen und WissenschaftlerInnen der Mitglieder des hgnc e.V. kostenlos. Um das Netzwerk zu verstetigen und die Serviceleistungen für Gründerinnen und Gründer auch dauerhaft anbieten zu können, gründeten die folgenden Partner am 24. November 2011 den hgnc e.V.`}</p>
    <h2>{`Die Mitglieder des hochschulgründernetz cologne e.V.`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cbs.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`CBS International Business School`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.dshs-koeln.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Deutsche Sporthochschule Köln`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.dlr.de/DE/Home/home_node.html",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Deutsches Zentrum für Luft- und Raumfahrt (DLR) e.V.`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.h-brs.de/de",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Hochschule Bonn-Rhein-Sieg`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.fom.de/hochschulzentren/studium-in-koeln.html",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Hochschule für Oekonomie & Management (FOM)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.macromedia.de/standorte/koeln.html",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Hochschule Macromedia`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ihk-koeln.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Industrie- und Handelskammer zu Köln (IHK)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ism.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`International School of Management (ISM)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ksk-koeln.de/de/home.html",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Kreissparkasse Köln`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.neuesunternehmertum.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Neues Unternehmertum Rheinland e.V. (NUK)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://rtz.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Rechtsrheinisches Technologie- und Gründerzentrum`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.tz-bg.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Rheinisch-Bergisches Technologiezentrum GmbH`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.rfh-koeln.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Rheinische Fachhochschule Köln`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.sparkasse-koelnbonn.de/de/home.html",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Sparkasse KölnBonn`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.stadt-koeln.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Stadt Köln`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.th-koeln.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Technische Hochschule Köln`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.uk-koeln.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Uniklinik Köln`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.uni-koeln.de/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Universität zu Köln`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nrwbank.de/de/foerderlotse-produkte/win-NRWBANK-Business-Angels-Initiative/15311/nrwbankproduktdetail.html",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`win Business Angels Initiative der NRW.Bank`}</a></li>
      <li parentName="ul">{`TODO: `}<a parentName="li" {...{
          "href": "https://www.mediengruenderzentrum.de/start/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://www.mediengruenderzentrum.de/start/`}</a></li>
    </ul>
    <h2>{`Vorstand, Geschäftsführung und Geschäftsstelle`}</h2>
    <p>{`Die Mitgliederversammlung des hgnc wählt den Vorstand gemäß seiner Satzung für die Dauer von 4 Jahren. Dem geschäftsführenden Vorstand gehören demnach an:`}</p>
    <p><strong parentName="p">{`Prof. Dr. Klaus Becker`}</strong>{`, Technische Hochschule Köln (Vorsitzender des Vorstands)`}</p>
    <p><strong parentName="p">{`Dr. Rainer Minz`}</strong>{`, Universität zu Köln (stellvertretender Vorsitzender des Vorstands)`}</p>
    <p><strong parentName="p">{`Prof. Dr. Kai Thierhoff`}</strong>{`, Rheinische Fachhochschule Köln`}</p>
    <p><strong parentName="p">{`Prof. Dr. Sebastian Uhrich`}</strong>{`, Deutsche Sporthochschule Köln (Schatzmeister)`}</p>
    <p><strong parentName="p">{`Verena Wagner`}</strong>{`, Deutsches Zentrum für Luft- und Raumfahrt e.V.`}</p>
    <p>{`Die Geschäftsführung liegt bei `}<strong parentName="p">{`Prof. Dr. Ulrich Anders`}</strong>{`, CBS International Business School.`}</p>
    <p>{`Die Geschäftsstelle des hgnc wird von der CBS International Business School geführt und von `}<strong parentName="p">{`Flora Carlhoff`}</strong>{` geleitet.`}</p>
    <h2>{`Professoren & Dozenten im Netzwerk`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ulrich-anders.eu",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Ulrich Anders`}</a>{`, CBS International Business School`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.th-koeln.de/personen/klaus.becker/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Klaus Becker`}</a>{`, TH Köln`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.rfh-koeln.de/studium/startuprfh/dozentinnen/index_ger.html",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Kai Bühler`}</a>{`, RFH Köln`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cbs.de/hochschule/team/prof-dr-elisabeth-froehlich/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Lisa Fröhlich`}</a>{`, CBS International Business School`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://portal.uni-koeln.de/subportale/universitaetsstiftung/universitaetsstiftung/gremien/cv-dr-rainer-minz",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Rainer Minz`}</a>{`, Uni Köln`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.th-koeln.de/personen/marc.prokop/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Marc Prokop`}</a>{`, TH Köln`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cbs.de/hochschule/team/prof-dr-mike-schallehn/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Mike Schallehn`}</a>{`, CBS International Business School`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ims.uni-koeln.de/de/team/der-lehrstuhl/prof-dr-christian-schwens",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Christian Schwens`}</a>{`, Uni Köln`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`Prof. Dr. Sebastian Uhrich`}</a>{`, Sporthochschule Köln`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.rfh-koeln.de/studium/startuprfh/dozentinnen/index_ger.html",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Kai Thierhoff`}</a>{`, RFH Köln`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.th-koeln.de/personen/kai.thuerbach/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Kai Thürbach`}</a>{`, TH Köln`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cbs.de/hochschule/team/prof-dr-christoph-willers/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Prof. Dr. Christoph Willers`}</a>{`, CBS International Business School`}</li>
    </ul>
    <h2>{`Engagement und Unterstützer`}</h2>
    <p><strong parentName="p">{`VISTRA`}</strong>{` – eine internationale Unternehmensgruppe für Wirtschaftsprüfung, Steuer- und Rechtsberatung sowie für Unternehmensberatung führt die Steuerberatungsleistungen für den hgnc e.V. durch.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      